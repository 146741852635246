<template>
    <div class="contain">
        <section
            class="item-group"
            v-for="(item, ind) in groupList.slice(0, 2)"
            :key="ind"
        >
            <div
                class="item"
                v-for="(san, kind) in item"
                :key="'s' + ind + kind"
                @click="toDetail(san)"
            >
                <div class="pic-group">
                    <div class="order">{{ san.id }}</div>
                    <img class="pic" :src="san.video_image" alt="" />
                    <div class="pic-title">
                        {{ san.title }}
                    </div>
                </div>
                <img
                    v-if="san.tipID == 1"
                    class="gift0"
                    src="./img/gift0.png"
                    alt=""
                />
                <img
                    v-if="san.tipID == 2"
                    class="gift0"
                    src="./img/gift1.png"
                    alt=""
                />
                <img
                    v-if="san.tipID == 3"
                    class="gift0"
                    src="./img/gift2.png"
                    alt=""
                />
                <div class="info">
                    <span class="txt-left">{{ san.nickname }}</span>
                    <span class="txt-right">{{ san.vote_num }}票</span>
                </div>
                <div
                    class="btn-tz-a"
                    v-if="!san.is_vote"
                    @click.stop="submitPiao(san)"
                >
                    投票
                </div>
                <div class="btn-tz-b" v-else>
                    <div class="san" @click.stop="submitPiao(san)">
                        <span>投票</span>
                    </div>
                    <div class="san abs" @click.stop="openShare">
                        <span>分享</span>
                        <img class="tip" src="./img/jiapiao.png" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <div
            style="margin-top: 14px;color:#6DC539;text-align: center;font-size:14px;"
        >
            <span @click="toAdd">我也要上评选 ></span>
        </div>
        <template v-if="storyList.length > 4">
            <section
                class="item-group"
                v-for="(item, ind) in groupList.slice(2)"
                :key="'t' + ind"
            >
                <div
                    class="item"
                    v-for="(san, kind) in item"
                    :key="'b' + ind + kind"
                    @click="toDetail(san)"
                >
                    <div class="pic-group">
                        <div class="order">{{ san.id }}</div>
                        <img class="pic" :src="san.video_image" alt="" />
                        <div class="pic-title">
                            {{ san.title }}
                        </div>
                    </div>

                    <div class="info">
                        <span class="txt-left">{{ san.nickname }}</span>
                        <span class="txt-right">{{ san.vote_num }}票</span>
                    </div>

                    <div
                        class="btn-tz-a"
                        v-if="!san.is_vote"
                        @click.stop="submitPiao(san)"
                    >
                        投票
                    </div>
                    <div class="btn-tz-b" v-else>
                        <div class="san" @click.stop="submitPiao(san)">
                            <span>投票</span>
                        </div>
                        <div class="san abs" @click.stop="openShare">
                            <span>分享</span>
                            <img class="tip" src="./img/jiapiao.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <div
                style="margin-top: 14px;color:#6DC539;text-align: center;font-size:14px;"
            >
                <span @click="toAdd">我也要上评选 ></span>
            </div>
        </template>

        <div
            style="margin-top: 14px;color:#999999;text-align: center;font-size:13px;"
        >
            到底啦～
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '../../api';
import { utils } from '@/common/js/mmcModule';
import saUtils from 'sensors-data-utils'

var reqObj = utils.getRequestParams();

export default {
    computed: {
        ...mapState(['userInfo', 'storyList']),
        groupList: function() {
            let temp = [];
            for (let i = 0; i < this.storyList.length; i += 2) {
                if (this.storyList[i]) {
                    if (this.storyList[i + 1]) {
                        temp.push([this.storyList[i], this.storyList[i + 1]]);
                    } else {
                        temp.push([this.storyList[i]]);
                    }
                }
            }

            return temp;
        },
    },
    methods: {
        submitPiao(san) {
            if (this.userInfo.activity_state == 1) {
                if (this.userInfo.subscribe == 0) {
                    this.$emit('showQrModal');
                    saUtils.popup('h5_pop_jsb_followwx')
                } else {
                    if (this.userInfo.vote_num == 0) {
                        if(!this.userInfo.is_donate){
                            this.$emit('showHelpModal');
                            saUtils.popup('h5_pop_jsb_zerotickets')
                        }else{
                            alertMsg('当前余0票，您可分享获得加票');
                        }
                       
                    } else {
                        showLoading('提交中');
                        console.log(san, 333);
                        api.postPiao({
                            story_id: Number(san.id),
                            share_uuid: reqObj.share_uuid,
                        }).then(res => {
                            hideLoading();
                            if (res.code == 0) {
                                
                                this.$store.commit('updateUserNum', san);
                                if(this.userInfo.vote_num==0){
                                    alertMsg('投票成功，转发好友投票成功得加票');
                                }else{
                                    alertMsg(`投票成功，今日还可投${this.userInfo.vote_num}票`);
                                }
                            }
                        });
                    }
                }
            }else{
                alertMsg('活动已结束');
            }
            saUtils.click('button_vote')
        },
        openShare() {
            this.$emit('showShareModal');
            saUtils.click('button_share')
        },
        toAdd() {
            location.href = 'https://jinshuju.net/f/sdJf2l';
        },
        toDetail(san) {
            location.href = '/fund/activity/lovesound/detail?id=' + san.id;
        },
    },
};
</script>

<style lang="less" scoped>
.contain {
    padding: 0px 16px 20px;
    border-radius: 0px 0px 0 0;
    text-align: left;
    background-color: #fff;
    overflow: hidden;
    .item-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .item {
            width: 48%;
            background-color: #fff;
            border: 2px solid #6dc539;
            border-radius: 10px;
            position: relative;
            .pic-group {
                border-radius: 8px 8px 0 0;
                position: relative;
                overflow: hidden;
                .order {
                    position: absolute;
                    text-align: center;
                    top: 12px;
                    left: 0px;
                    // height: 30px;
                    
                    width: 43px;
                    background-color: #6dc539;
                    font-size: 17px;
                    line-height: 1.4em;
                    padding: 4px 0;
                    padding-right: 4px;
                    color: #fff;
                    font-weight: 500;
                    
                    border-radius: 0  24px 24px 0;
                }

                .pic {
                    width: 100%;
                    padding: 0;
                    display: block;
                    min-height: 218px;
                    max-width: 100%;
                }

                .pic-title {
                    position: absolute;
                    font-size: 13px;
                    color: #fff;
                    font-weight: 500;
                    // left: 12px;
                    bottom: 7px;
                    //单行不换行省略号
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                    padding: 0 7px;
                }
            }
            .gift0 {
                position: absolute;
                width: 30px;
                height: 30px;
                right: -6px;
                top: -6px;
            }
            .info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                margin: 10px 7px 0;
                .txt-left {
                    flex: 1;
                    font-size: 15px;
                    font-weight: 500;
                    color: #333;
                    line-height: 1.4em;
                    //单行不换行省略号
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                    // padding: 0 7px;
                }
                .txt-right {
                    font-size: 15px;
                    color: #999;
                }
            }

            .btn-tz-a {
                margin: 20px 12px 10px;
                text-align: center;
                font-size: 14px;
                color: #fff;
                font-weight: 600;
                line-height: 1.4em;
                background-image: linear-gradient(180deg, #ffce6a, #ff6217);
                border-radius: 14px;
                padding: 5px 14px;
            }
            .btn-tz-b {
                margin: 20px 12px 10px;

                display: flex;
                align-items: center;
                justify-content: space-between;
                .san {
                    width: 48%;
                    background-image: linear-gradient(180deg, #ffce6a, #ff6217);
                    border-radius: 14px;
                    color: #fff;
                    text-align: center;
                    font-size: 14px;
                    color: #fff;
                    font-weight: 500;
                    line-height: 1.4em;
                    padding: 5px 14px;
                }
                .abs {
                    position: relative;
                    .tip {
                        width: 93px;
                        height: 20px;
                        position: absolute;
                        right: -5px;
                        top: -20px;
                    }
                }
            }
        }
    }
}
</style>

import Vuex from 'vuex'
import {
    utils
} from '@/common/js/mmcModule';


var reqObj = utils.getRequestParams();
import mock from './mock.js';

const storePublish = new Vuex.Store({
    state: {
        storyList: [],
        userInfo: {},
    },
    mutations: {

        setStoryList(state, arr) {
            state.storyList = arr;
            // console.log(state.storyList, 33, arr)
            if (reqObj.isMock == 1) {
                // state.storyList = mock.storyArr;
                // console.log(state.storyList.length, 'sdf')
            }

            state.storyList.forEach((item, index) => {
                item.tipID = index + 1
            })
        },
        setUserInfo(state, obj) {
            state.userInfo = obj;

            if (reqObj.isMock == 1) {
                state.userInfo.subscribe = 1;
                state.userInfo.vote_num = 0;
            }

        },
        updateUserNum(state, obj) {
            state.userInfo.vote_num -= 1;
            obj.vote_num += 1;
            obj.is_vote = true
        }
    },

})

export default storePublish;
<template>
    <div v-if="dataCom" class="my-wrap">
        <div class="cw-top">
            <img style="display:block" src="./img/top-bg-index.png" alt="" />
            <!-- <div class="temp-num">{{ userInfo.vote_num }}</div> -->
            <div class="shuoming" @click="shuoming"></div>
            <div class="canyu" @click="canyu"></div>
        </div>
        <congContain
            @showQrModal="qrModalVisible = true"
            @showShareModal="shareModalVisible = true"
            @showHelpModal="projModalVisible = true"
            style="margin-top: 0px;position: relative;"
        />
        <projContain
            :gongYiList="gongYiList"
            :congWuList="congWuList"
            style="margin-top: 16px;"
        />

        <div style="height:80px;background-color:#fff"></div>
        <shareModal :visible.sync="shareModalVisible" />
        <projectModal :dataObj="gyObj" :visible.sync="projModalVisible" @closeModal="closeModalProj"/>
        <qrModal :visible.sync="qrModalVisible" @closeModal="closeModalQr"></qrModal>
        <guiZe :visible.sync="gzModalVisible" />
    </div>
</template>

<script>
import projContain from './cpt/projContain/index.vue';
import congContain from './cpt/congContain/index.vue';
import guiZe from './cpt/guiZe/index.vue';
import qrModal from '../common/qrModal/index.vue';
import shareModal from '../common/shareModal';
import projectModal from '../common/projectModal';
import store from './store';
import api from './api';
import { authChecker, shareCtrl, tracking, utils } from '@/common/js/mmcModule';
import { mapState } from 'vuex';


const pageKey = 'h5_jsb_lovesound_index';
import saUtils from 'sensors-data-utils';


export default {
    data() {
        return {
            qrModalVisible: false,
            shareModalVisible: false,
            projModalVisible: false,
            gzModalVisible: false,
            dataCom: false,
            gongYiList: [],
            congWuList: [],
        };
    },
    computed: {
        ...mapState(['userInfo']),
        gyObj() {
            let n = Math.floor(Math.random() * this.gongYiList.length);
            this.gongYiList[n].useruuid = this.userInfo.uuid;
            return this.gongYiList[n];
        },
    },

    store,

    components: {
        projContain,
        congContain,
        qrModal,
        shareModal,
        projectModal,
        guiZe,
    },
    methods: {
        canyu() {
            location.href = 'https://jinshuju.net/f/sdJf2l';
        },
        shuoming() {
            this.gzModalVisible = true;
        },
        closeModalQr(){
            saUtils.click('button_close_followwx','h5_jsb_lovesound_index_followwx')
            
        },
        closeModalProj(){
            saUtils.click('button_close_zerotickets','h5_jsb_lovesound_index_zerotickets')
        }
    },
    created() {
        let prUserInfo = api.getUserInfo().then(res => {
            if (res.code == 0) {
                this.$store.commit('setUserInfo', res.data);
            }
        });
        let prStoryList = api.getStoryList().then(res => {
            if (res.code == 0) {
                this.$store.commit('setStoryList', res.data);
            }
        });
        let prCongWuList = api.getCongWuList();
        let prGongYiList = api.getGongYiList();

        authChecker.check({ notneedphone: 1 }, res => {
            showLoading('加载中...');
            Promise.all([
                prUserInfo,
                prStoryList,
                prCongWuList,
                prGongYiList,
            ]).then(resArr => {
                this.dataCom = true;
                hideLoading();

                this.congWuList = resArr[2].data.list;
                this.gongYiList = resArr[3].data;
                shareCtrl.init({
                    title: '为流浪动物发声',
                    imgUrl: `https://m.jisuchou.net/fund/love-sound-share.png`,
                    desc: '点击链接，为流浪的毛孩子发声',
                    url: `https://m.jisuchou.net/fund/activity/lovesound/index?share_uuid=${this.userInfo.uuid}`,
                    titleMoment: '为流浪动物发声',
                    shareRecord: {
                        scene: pageKey,
                    },
                });


                tracking.init();
                saUtils.init(pageKey);
                saUtils.pv();
            });
        });
    },
};
</script>
<style lang="less" scoped>
.my-wrap {
    background-color: #f2f2f2;
    .cw-top {
        position: relative;
        min-height: 140px;
        .temp-num {
            background-color: #fff;
            position: absolute;
            width: 100px;
            height: 100px;
            left: 0;
            top: 0;
            color: #000;
            z-index: 12;
        }
        .shuoming {
            background-color: rgba(255, 0, 0, 0);
            position: absolute;
            width: 30px;
            height: 90px;
            right: 0;
            top: 0;
            z-index: 12;
        }
        .canyu {
            background-color: rgba(255, 0, 0, 0);
            position: absolute;
            width: 90px;
            height: 60px;
            right: 0;
            bottom: 60px;
            z-index: 13;
        }
    }
}
</style>

<template>
    <div v-if="visible" class="rule-wrap">
        <div class="content">
            <i class="close-btn" @click="closeModal"></i>

            <div class="title">活动规则</div>
            <div class="text-wrap">
                
                <p>投票规则：</p>
                <p>活动期间，每人每天可投一票，分享好友投票可额外增加1次投票机会，为流浪动物捐助可增加5次投票机会（每天仅第一次捐助，可增加投票机会）。</p>
                <p>活动时间：11月10日～11月30日</p>
                <br>
                <p>活动奖励：</p>
                <p>第一名：800斤宠粮</p>
                <p>第二名：600斤宠粮</p>
                <p>第三名：500斤宠粮</p>
                <p>第4 - 10名：100斤宠粮</p>
                <p>第11 - 20名：50斤宠粮</p>
                <p>参与奖：未进入前20名，获得超300名网友助力，可获得10斤宠粮奖励。</p>
                <p>禁止刷票规则：活动新增加防刷票机制，被判定为刷票，第一次取消刷票增加的投票数量，第二次发现取消活动参与资格。</p>
                
            </div>
            <div class="btn" @click="closeModal2">知道了</div>
        </div>
    </div>
</template>

<script>
import saUtils from 'sensors-data-utils';
export default {
    name: 'modalKeYong',
    props: ['visible'],
    data() {
        return {
            text: '',
        };
    },

    methods: {
        closeModal() {
            this.$emit('update:visible', false);
            saUtils.click(
                'button_close_applyrulespop',
                'h5_pop_jsb_onepoint_apply_rules'
            );
        },
        closeModal2() {
            this.$emit('update:visible', false);
            saUtils.click(
                'button_know_rulespop',
                'h5_pop_jsb_onepoint_apply_rules'
            );
        },
    },

    mounted() {
        // alert(2)
    },
};
</script>

<style scoped lang="less">
.rule-wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 300;
    .content {
        // margin: 0 34px;
        // height: 410px;
        // padding-bottom: 40px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 8px;
        width: 86%;
        position: absolute;
        &:before {
            content: '';
            display: table;
        }
        .title {
            font-size: 18px;
            color: #333;
            text-align: center;
            margin: 20px auto 20px;
            line-height: 1em;
            font-weight: 500;
        }
        .text-wrap {
            // height: 320px;
            padding: 0 15px;
            overflow-y: scroll;
            overflow-x: hidden;
            text-align: left;
            color: #666;
            font-size: 14px;
            line-height: 1.4em;
        }
        .btn {
            padding: 13px 30px;
            // width: 255px;
            // background-image: linear-gradient(to right, #6DC53A, #e91919);
            background-color: #6dc838;

            border-radius: 25px;
            line-height: 1em;
            font-size: 18px;
            color: #fff;
            min-width: 130px;
            font-weight: 600;
            margin: 24px 16px;
        }
    }

    .small-lion {
        position: absolute;
        left: 12px;
        top: -15px;
        width: 49px;
        height: 47px;
    }

    .close-btn {
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 8px;
        right: 8px;
        z-index: 10;
        // border: 1px solid #999;
        // border-radius: 15px;

        &:after,
        &:before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 16px;
            background: #c3c3c3;
            position: absolute;
            left: 14px;
            top: 7px;
            transform: rotate(45deg);
        }
        &:before {
            transform: rotate(-45deg);
        }
    }
}
</style>

<template>
    <div class="contain">
        <div class="title">参与动物救助得额外投票机会</div>
        <section v-for="(item, ind) in gongYiList" :key="ind">
            <div class="item" @click="toHelpGongYi(item)">
                <img
                    class="pic"
                    :src="item.project_thumb + '@!thumb.png'"
                    alt=""
                />
                <div class="info">
                    <section class="title-group">
                        <div class="proj-name">
                            {{ item.title }}
                        </div>
                        <div class="btn-tz">
                            <span @click.stop="toHelpGongYi(item)">帮帮ta</span>
                            <img v-if="!userInfo.is_donate" 
                                class="tip-five"
                                src="./img/tip-five.png"
                                alt=""
                            />
                        </div>
                    </section>
                    <section class="num-group">
                        <div>
                            已筹：<span class="yellow"
                                >{{ item.accumulative_number / 100 }}元</span
                            >
                        </div>
                        <div>
                            目标：<span class="yellow"
                                >{{ item.raisefunds_number / 100 }}元</span
                            >
                        </div>
                    </section>
                </div>
            </div>
        </section>
        <section v-for="(item, ind2) in congWuList" :key="'s' + ind2">
            <div class="item" @click="toHelpCongWu(item)">
                <img
                    class="pic"
                    :src="item.project_thumb + '@!thumb.png'"
                    alt=""
                />
                <div class="info">
                    <section class="title-group">
                        <div class="proj-name">
                            {{ item.title }}
                        </div>
                        <div class="btn-tz">
                            <span @click.stop="toHelpCongWu(item)">帮帮ta</span>
                            <img v-if="!userInfo.is_donate"
                                class="tip-five"
                                src="./img/tip-five.png"
                                alt=""
                            />
                        </div>
                    </section>
                    <section class="num-group">
                        <div>
                            已筹：<span class="yellow"
                                >{{ item.accumulative_number / 100 }}元</span
                            >
                        </div>
                        <div>
                            目标：<span class="yellow"
                                >{{ item.raisefunds_number / 100 }}元</span
                            >
                        </div>
                    </section>
                </div>
            </div>
        </section>
        <div
            style="margin-top: 14px;color:#999999;text-align: center;font-size:13px;"
        >
            到底啦～
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import groupUnifiedTracking from '@/common/js/groupUnifiedTracking';
export default {
    props: ['gongYiList', 'congWuList'],
    computed: {
        ...mapState(['userInfo']),
    },
    methods: {
        toHelpGongYi(item) {
            let tzUrl  = groupUnifiedTracking.updateUrl(`https://yglian.qschou.com/gongyi/publicSite/detail?ChannelId=${item.channel}&id=${item.uuid}&mp=petuser.${this.userInfo.uuid}`,'button.p.20231106.activeindex.bottomhelp')
            // console.log(tzUrl)
            location.href = tzUrl;
        },
        toHelpCongWu(item) {
            location.href = `https://m.jisuchou.net/fund/project/detail?projuuid=${item.uuid}&mp=petuser&rc=button.p.20231106.activeindex.bottomhelp`;
        },
    },
};
</script>

<style lang="less" scoped>
.contain {
    padding: 20px 16px;
    border-radius: 10px 10px 0 0;
    text-align: left;
    background-color: #fff;
    .title {
        color: #333;
        font-weight: 500;
        line-height: 1.4em;
        font-size: 18px;
    }
    .item {
        display: flex;
        align-items: center;
        justify-content: left;
        border-bottom: 1px solid #eee;
        padding: 15px 0;
        .pic {
            width: 90px;
            height: 90px;
            border-radius: 8px;
            margin-right: 10px;
        }
        .info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 90px;

            .title-group {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                .proj-name {
                    font-size: 15px;
                    color: #333;
                    font-weight: 500;
                    line-height: 1.4em;
                    //多行省略
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .btn-tz {
                    position: relative;
                    min-width: 70px;
                    margin-top: 22px;
                    margin-left: 25px;
                    span {
                        font-size: 15px;
                        color: #fff;
                        font-weight: 500;
                        line-height: 1.4em;
                        display: inline-block;
                        background-image: linear-gradient(
                            90deg,
                            #ffce6a,
                            #ff6217
                        );
                        border-radius: 14px;
                        padding: 5px 14px;
                        //不换行
                        white-space: nowrap;    
                    }

                    .tip-five {
                        position: absolute;
                        top: -30px;
                        right: 0px;
                        width: 90px;
                        height: 26px;
                    }
                }
            }

            .num-group {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                font-size: 13px;
                line-height: 1.4em;
                color: #999;
                font-weight: 500;
                .yellow {
                    color: #ff7b39;
                }
            }
        }
    }
}
</style>
